import { ReactComponent as MoneyBackIcon } from './Money-back-guarantee.svg';
import { ReactComponent as NoHiddenFeesIcon } from './no-hidden-fees.svg';
import { ReactComponent as SupportIcon } from './24-7-support.svg';

export default {
	heading: 'Your hiring solution',
	items: [
		{
			title: 'Join free',
			description:
				'Find and keep good people with an easy workspace that gets results.',
			icon: MoneyBackIcon,
			link: 'https://help.hostjane.com/getting-started/',
			linkLabel: 'How it works',
			internalLink: false,
		},
		{
			title: 'Approve job',
			description:
				'Funds held with PayPal or Amazon Pay until the job is done on time.',
			icon: NoHiddenFeesIcon,
			link: '/legal/marketplace-terms/',
			linkLabel: 'Ground rules',
			internalLink: true,
		},
		{
			title: 'VISA® payouts',
			description:
				'Earnings sent to Seller\'s VISA® debit or credit card, PayPal, Venmo, or bank.',
			icon: SupportIcon,
			link: 'https://help.hostjane.com/sellers/payments-tax-credits/',
			linkLabel: 'More info',
			internalLink: false,
		},
	],
};
