import React from 'react';
import { Link } from 'gatsby';
import {
	HIWItem,
	PaymentShield,
	Article,
	SmallBorderedParagraph,
	SmallFooterParagraph,
} from '../../src/components/StyledContents';
import { Modal, ModalBody, ModalHeader } from '../../src/components/Modal';

// TITLE OF MODAL
export const title = 'How It Works';

// REACT COMPONENT FOR MODAL CONTENT
export function ModalContent() {
	return (
		<Article>
			<SmallBorderedParagraph paddingBottom>
			Hire people with refund protection, an <Link to="https://help.hostjane.com/getting-started/">awesome workspace</Link>, and <Link to="/legal/marketplace-terms/">clear terms</Link> with itemized receipts.
			</SmallBorderedParagraph>
			<HIWItem fullWidth index={0} title="Create a free account">
			<Link to="https://www.hostjane.com/marketplace/register">Sign up</Link> or <Link to="https://www.hostjane.com/marketplace/login">sign in</Link>{' '} securely by email, Facebook, Apple, Google, Twitter or Telegram.
			</HIWItem>
			<HIWItem fullWidth index={1} title="Browse services">
			<Link to="https://www.hostjane.com/marketplace/">Message Sellers</Link> and get <Link to="https://help.hostjane.com/buyers/#get-free-quotes">free quotes</Link>. Place funds on hold through PayPal or Amazon Pay.
			</HIWItem>
			<HIWItem fullWidth index={2} title="Get your work done">
				Our <Link to="https://help.hostjane.com/getting-started/what-are-boards/#what-are-boards">boards, lists, and cards</Link>, with chat, let you work with freelancers and teachers.
			</HIWItem>
			<HIWItem fullWidth index={3} title="Pay on approval">
				Release funds to your Seller when you're happy with the work, or your booking ends. 
				<PaymentShield title="Purchase Protection">
					Your money back if Work is unsatisfactory or late.
				</PaymentShield>
			</HIWItem>
			<HIWItem index={4} fullWidth hasFlame title="Rate your seller">
				Your feedback is important to us. 5 flames are for heroes.
				You can tip people in messages.
			</HIWItem>
			<SmallFooterParagraph>
				All purchases (except Tips) are subject to a non-refundable Handling Fee. This pays for platform overheads including admin, hosting, marketing, data costs and 24×7×365 support.
			</SmallFooterParagraph>
		</Article>
	);
}

// DONT EDIT THIS
export function SafeGuardModal({ open, closeModal }) {
	return (
		<Modal isOpen={open} closeModal={closeModal} contentLabel={title}>
			<ModalHeader title={title} closeModal={closeModal} />
			<ModalBody>
				<ModalContent />
			</ModalBody>
		</Modal>
	);
}
